<template>
	<div class="flex-row align-items-center">
		<CRow class="justify-content-center headimg mb-3">
			<CCol md="12" class="p-0">
				<div style="background-image: url('http://simpel1.pertanian.go.id/api/images/Dashboard.png'); background-position: 50% 50%; background-size: cover; height: 150px;">
					<h2 class="text-center text-white middle-center">
						<b>Profile, <span class="text-success">{{session_nama}}</span></b>
					</h2>
				</div>
			</CCol>
		</CRow>
		<div class="container">
			<CRow class="justify-content-center">
				<CCol md="12">
					<CCard class="top-radius-15">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h4 class="my-0 text-center"><b>Data Profile</b></h4>
						</CCardHeader>
						<CCardBody>
							<template>
								<CTabs variant="tabs" class="a-black" :active-tab="0" style="color: #333;">
									<CTab title="Data Akun">
										<CRow class="mt-3">
											<!-- <div class="col-md-6">
												<div class="form-group">
													<b>NIP <span class="text-danger">*</span></b>
													<input type="text" class="form-control" id="nip" />
												</div>
											</div> -->
											<div class="col-md-6">
												<div class="form-group">
													<b>Nama<span class="text-danger">*</span></b>
													<input type="text" class="form-control" id="nama" />
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<b>Email Pendaftar <span class="text-danger">*</span></b>
													<input type="email" class="form-control" id="email" />
												</div>
											</div>
											<!-- <div class="col-md-6">
												<div class="form-group">
													<b>NIB<span class="text-danger">*</span></b>
													<input type="text" class="form-control" id="nibmember" />
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<b>No. Telp <span class="text-danger">*</span></b>
													<input type="text" class="form-control" id="phone" />
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<b>NPWP <span class="text-danger">*</span></b>
													<input type="text" class="form-control" id="npwpmember" />
												</div>
											</div> -->
											<div class="col-md-6">
												<div class="form-group">
													<b>Password <span class="text-danger">*</span></b>
													<input type="password" class="form-control" id="password" />
												</div>
											</div>
											<div class="col-md-6">
												<div class="form-group">
													<b>Konfirmasi Password <span class="text-danger">*</span></b>
													<input type="password" class="form-control" id="confirmpassword" />
												</div>
											</div>
											<div class="col-md-12">
												<button class="btn btn-success text-center btn-block" v-on:click="updateDataUser">Simpan</button>
                        <button
                          class="btn btn-info text-center btn-block"
                          v-on:click="showModalSync"
                        >
                          Sinkron akun ke aplikasi Portal Perizinan
                        </button>
												<small>*) Isi password untuk edit data anda.</small>
											</div>
										</CRow>
									</CTab>
									<CTab title="Perbarui Perusahaan" v-if="data_perusahaan">
										<CRow>
											<CCol md="5">
												<div class="form-group">
													<b>NIB Perusahaan</b>
													<input type="text" class="form-control" v-model="nib_sync">
												</div>
											</CCol>
											<CCol md="7">
												<div class="btn btn-primary" style="margin-top: 20px;" v-on:click="syncPerusahaan"><i class="fa fa-save"></i> Perbarui Perusahaan</div>
											</CCol>
											<CCol md="12">
													*) Data Perusahaan Terakhir Diperbarui {{tgl_perbarui}}
											</CCol>
										</CRow>
									</CTab>
									<CTab title="Data Perusahaan" v-if="data_perusahaan">
										<div class="mt-3">
											<template>
												<CTabs variant="pills" :active-tab="0">
													<CTab title="Profile">
														<table class="table table-hover table-striped table-bordered table-sm mt-3">
															<tbody>
																<tr v-for="(value_data_profile_perusahaan, key_data_profile_perusahaan) in data_profile_perusahaan" :key="key_data_profile_perusahaan">
																	<th width="350">{{key_data_profile_perusahaan}}</th>
																	<td>{{value_data_profile_perusahaan}}</td>
																</tr>
															</tbody>
														</table>
													</CTab>
													<CTab title="Data Infestasi">
														<table class="table table-hover table-striped table-bordered table-sm mt-3">
															<tbody>
																<tr v-for="(value_data_infestasi, key_data_infestasi) in data_infestasi" :key="key_data_infestasi">
																	<th width="350">{{key_data_infestasi}}</th>
																	<td>{{value_data_infestasi}}</td>
																</tr>
															</tbody>
														</table>
													</CTab>
													<CTab title="Pemegang Saham">
														<div>
															<CCard class="mb-0" v-for="(value_data_pemegang_saham, key_data_pemegang_saham) in data_pemegang_saham" v-bind:key="key_data_pemegang_saham">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_data_pemegang_saham ? false : key_data_pemegang_saham"
																>
																	<h5 class="m-0">Pemegang Saham #{{key_data_pemegang_saham+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_data_pemegang_saham">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_data_pemegang_saham_data, key_data_pemegang_saham_val) in value_data_pemegang_saham" :key="key_data_pemegang_saham_val">
																					<th width="350">{{key_data_pemegang_saham_val}}</th>
																					<td>{{value_data_pemegang_saham_data}}</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
													<CTab title="Penanggung Jawab">
														<div>
															<CCard class="mb-0" v-for="(value_penanggung_jawab, key_penanggung_jawab) in data_penanggung_jawab" v-bind:key="key_penanggung_jawab">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_penanggung_jawab ? false : key_penanggung_jawab"
																>
																	<h5 class="m-0">Penanggung Jawab #{{key_penanggung_jawab+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_penanggung_jawab">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_penanggung_jawab_data, key_penanggung_jawab_data) in value_penanggung_jawab" :key="key_penanggung_jawab_data">
																					<th width="350">{{key_penanggung_jawab_data}}</th>
																					<td>{{value_penanggung_jawab_data}}</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
													<CTab title="Legalitas">
														<div>
															<CCard class="mb-0" v-for="(value_legalitas, key_legalitas) in data_legalitas" v-bind:key="key_legalitas">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_legalitas ? false : key_legalitas"
																>
																	<h5 class="m-0">Legalitas #{{key_legalitas+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_legalitas">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_legalitas_data, key_legalitas_data) in value_legalitas" :key="key_legalitas_data">
																					<th width="350">{{key_legalitas_data}}</th>
																					<td>{{value_legalitas_data}}</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
													<CTab title="RPTKA Jabatan"> </CTab>
													<CTab title="RPTKA Negara">
														<div>
															<CCard class="mb-0" v-for="(value_rptka_negara, key_rptka_negara) in data_rptka_negara" v-bind:key="key_rptka_negara">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_rptka_negara ? false : key_rptka_negara"
																>
																	<h5 class="m-0">Negara #{{key_rptka_negara+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_rptka_negara">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_rptka_negara_data, key_rptka_negara_data) in value_rptka_negara" :key="key_rptka_negara_data">
																					<th width="350">{{key_rptka_negara_data}}</th>
																					<td>{{value_rptka_negara_data}}</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
													<CTab title="RPTKA Lokasi">
														<div>
															<CCard class="mb-0" v-for="(value_rptka_lokasi, key_rptka_lokasi) in data_rptka_lokasi" v-bind:key="key_rptka_lokasi">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_rptka_lokasi ? false : key_rptka_lokasi"
																>
																	<h5 class="m-0">Lokasi #{{key_rptka_lokasi+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_rptka_lokasi">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_rptka_lokasi_data, key_rptka_lokasi_data) in value_rptka_lokasi" :key="key_rptka_lokasi_data">
																					<th width="350">{{key_rptka_lokasi_data}}</th>
																					<td>{{value_rptka_lokasi_data}}</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
													<CTab title="Data DNI">
														<div>
															<CCard class="mb-0" v-for="(value_data_dni, key_data_dni) in data_data_dni" v-bind:key="key_data_dni">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_data_dni ? false : key_data_dni"
																>
																	<h5 class="m-0">Data DNI #{{key_data_dni+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_data_dni">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_data_dni_data, key_data_dni_data) in value_data_dni" :key="key_data_dni_data">
																					<th width="350">{{key_data_dni_data}}</th>
																					<td>{{value_data_dni_data}}</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
													<CTab title="Data Checklist">
														<div>
															<CCard class="mb-0" v-for="(value_data_checklist, key_data_checklist) in data_data_checklist" v-bind:key="key_data_checklist">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_data_checklist ? false : key_data_checklist"
																>
																	<h5 class="m-0">Data DNI #{{key_data_checklist+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_data_checklist">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_data_checklist_data, key_data_checklist_data) in value_data_checklist" :key="key_data_checklist_data">
																					<th width="350">{{key_data_checklist_data}}</th>
																					<td>{{value_data_checklist_data}}</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
													<CTab title="Data Proyek">
														<div>
															<CCard class="mb-0" v-for="(value_data_proyek, key_data_proyek) in data_data_proyek" v-bind:key="key_data_proyek">
																<CButton
																	block
																	color="link"
																	class="text-left shadow-none card-header"
																	@click="accordion = accordion === key_data_proyek ? false : key_data_proyek"
																>
																	<h5 class="m-0">Data Proyek #{{key_data_proyek+1}}</h5>
																</CButton>
																<CCollapse :show="accordion === key_data_proyek">
																	<CCardBody>
																		<table class="table table-hover table-striped table-bordered table-sm mt-3">
																			<tbody>
																				<tr v-for="(value_data_proyek_data, key_data_proyek_data) in value_data_proyek" :key="key_data_proyek_data">
																					<th width="350">{{key_data_proyek_data}}</th>
																					<td>
																						{{value_data_proyek_data}}
																					</td>
																				</tr>
																			</tbody>
																		</table>
																	</CCardBody>
																</CCollapse>
															</CCard>
														</div>
													</CTab>
												</CTabs>
											</template>
										</div>
									</CTab>
									<CTab title="Daftar Member Perusahaan"> 
										<div v-on:click="showAddMember" class="btn btn-primary btn-hover-outline-primary mt-3" v-if="!this.session_data.is_anggota"><i class="fa fa-plus"></i> Tambah Member</div>
										<table class="table table-hover table-striped table-bordered table-sm mt-3">
											<thead class="bg-success text-center text-white">
												<tr>
													<th style="width: 15px">No</th>
													<th>Nama</th>
													<th>Email</th>
													<th >Aksi</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(value_data_member, key_data_member) in data_data_member" v-bind:key="key_data_member">
													<td>{{key_data_member + 1}}</td>
													<td>{{value_data_member.name}}</td>
													<td>{{value_data_member.email}}</td>
													<td >
														<button square="" type="button" class="btn btn-info btn-sm" v-on:click="showEditMember(value_data_member.id)"><i class="fa fa-trash"></i> Edit</button>
														<button square="" type="button" class="btn btn-danger btn-sm" v-on:click="deleteDataMember(value_data_member.id)"><i class="fa fa-trash"></i> Hapus</button>
													</td>
												</tr>
											</tbody>
										</table>
									</CTab>
									<!-- <CTab title="Data Instansi">
										
									</CTab> -->
									<CTab title="Perbarui Data Instansi" v-if="data_perusahaan_register.tipe_pemohon_id == 2"> 
										<div class="row">										
											<div class="col-md-12 mt-3">
												<div class="form-group">
													<b for="">Tipe Penyelenggara <span class="text-danger">*</span></b>
													<input type="text" v-model="data_perusahaan_register.tipe_pemohon.tp_nama" disabled class="form-control">
												</div>
												<div class="form-group">
													<b for="">Email Instansi Pemerintah <span class="text-danger">*</span></b>
													<input type="text" class="form-control" placeholder="Masukkan Email" v-model="data_perusahaan_register.p_email">
												</div>
												<div class="form-group">
													<b for="">Nama Perusahaan / Perorangan <span class="text-danger">*</span></b>
													<input type="text" class="form-control" placeholder="Masukkan Nama Perusahaan" v-model="data_perusahaan_register.p_nama">
												</div>
												<div class="form-group">
													<b for="">Nama Penanggung Jawab <span class="text-danger">*</span></b>
													<input type="text" class="form-control" placeholder="Masukkan Nama Penanggung Jawab" v-model="data_perusahaan_register.p_penanggung_jawab">
												</div>
												<div class="form-group">
													<b for="">Jenis Usaha <span class="text-danger">*</span></b>
													<v-select :options="master_jenis_perusahaan" label="jp_nama" multiple v-model="jenis_usaha"></v-select>
												</div>
												<div class="form-group d-none">
													<b for="">Klasifikasi Baku Lapangan Usaha Indonesia (KBLI) <span class="text-danger">*</span></b>
													<v-select multiple :options="master_kbli_perusahaan" label="kp_nama" v-model="perusahaan_kbli"></v-select>
												</div>
												<div class="form-group">
													<b for="">Nomor Pokok Wajib Pajak (NPWP) <span class="text-danger">*</span></b>
													<input type="text" class="form-control" placeholder="Masukkan NPWP" v-model="data_perusahaan_register.p_npwp" >
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<b for="">No. Telp <span class="text-danger">*</span></b>
															<input type="text" class="form-control" placeholder="Masukkan No. Telp"  v-model="data_perusahaan_register.p_telp">
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<b for="">No. Handphone <span class="text-danger">*</span></b>
															<input type="text" class="form-control" placeholder="Masukkan No. Handphone" v-model="data_perusahaan_register.p_hp">
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<b for="">FAX <span class="text-danger">*</span></b>
															<input type="text" class="form-control" placeholder="Masukkan FAX" v-model="data_perusahaan_register.p_fax">
														</div>
													</div>
												</div>
												<div class="row">
													<div class="col-md-4">
														<div class="form-group">
															<b for="">Provinsi<span class="text-danger">*</span></b>
															<!-- <input type="text" :value="provinsi_selected.pr_nama" class="form-control"> -->
															<v-select :options="master_provinsi" label="pr_nama" v-model="provinsi_selected" @input="kota_selected = [], kecamatan_selected = []"></v-select>
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<b for="">Kabupaten / Kota <span class="text-danger">*</span></b>
															<v-select :options="provinsi_selected.kota" label="k_nama" v-model="kota_selected" @input="kecamatan_selected = []"></v-select>
														</div>
													</div>
													<div class="col-md-4">
														<div class="form-group">
															<b for="">Kecamatan <span class="text-danger">*</span></b>
															
															<v-select :options="kota_selected.kecamatan" label="kc_nama" v-model="kecamatan_selected"></v-select>

														</div>
													</div>
												</div>
												<div class="form-group">
													<b for="">Kode Pos <span class="text-danger">*</span></b>
													<input type="text" class="form-control" v-model="data_perusahaan_register.p_kode_pos">
												</div>
												<div class="form-group">
													<b>Alamat ( Sesuai NPWP ) <span class="text-danger">*</span></b>
													<textarea class="form-control" rows="4"  v-model="data_perusahaan_register.p_alamat"></textarea>
												</div>
											</div>
											<div class="col-12">
												<button class="btn btn-success text-center btn-block" v-on:click="updatePerusahaanRegister"><i class="fa fa-save"></i> Simpan</button>
											</div>
										</div>
									</CTab>
								</CTabs>
							</template>
						</CCardBody>
					</CCard>
					<template>
					  <div>
					    <CModal
					      title="Tambah Member"
					      color="btn-primary"
					      :show.sync="modalTambahMember"
					    >
					    <CRow>
						    <CCol md="12">
								<div class="form-group">
									<b>Nama<span class="text-danger">*</span></b>
									<input type="text" class="form-control" id="memberbaru-nama" />
								</div>
							</CCol>
							<CCol md="12">
								<div class="form-group">
									<b>Email Pendaftar <span class="text-danger">*</span></b>
									<input type="email" class="form-control" id="memberbaru-email" />
								</div>
							</CCol>
							<CCol md="12">
								<div class="form-group">
									<b>Password <span class="text-danger">*</span></b>
									<input type="password" class="form-control" id="memberbaru-password" />
								</div>
							</CCol>
							<CCol md="12">
								<div class="form-group">
									<b>Konfirmasi Password <span class="text-danger">*</span></b>
									<input type="password" class="form-control" id="memberbaru-confirmpassword" />
								</div>
							</CCol>
						</CRow>
						<template #footer>
						    <CButton @click="modalTambahMember = false" color="secondary">Tutup</CButton>
						    <CButton v-on:click="registerMemberProcess()" color="primary">Simpan</CButton>
						</template>
					    </CModal>
					  </div>
					</template>
					<template>
					  <div>
					    <CModal
					      title="Tambah Member"
					      color="btn-primary"
					      :show.sync="modalEditMember"
					    >
					    <CRow>
						    <CCol md="12">
								<div class="form-group">
									<b>Nama<span class="text-danger">*</span></b>
									<input type="text" class="form-control" v-model="memberbaru_nama" />
								</div>
							</CCol>
							<CCol md="12">
								<div class="form-group">
									<b>Email Pendaftar <span class="text-danger">*</span></b>
									<input type="email" class="form-control" v-model="memberbaru_email" />
								</div>
							</CCol>
							<CCol md="12">
								<div class="form-group">
									<b>Password <span class="text-danger">*</span></b>
									<input type="password" class="form-control" v-model="memberbaru_password" />
								</div>
							</CCol>
							<CCol md="12">
								<div class="form-group">
									<b>Konfirmasi Password <span class="text-danger">*</span></b>
									<input type="password" class="form-control" v-model="memberbaru_confirmpassword" />
								</div>
							</CCol>
						</CRow>
						<template #footer>
						    <CButton @click="modalEditMember = false" color="secondary">Tutup</CButton>
						    <CButton v-on:click="editMemberProcess()" color="primary">Simpan</CButton>
						</template>
					    </CModal>
					  </div>
					</template>
					         <template>
            <div>
              <CModal
                title="Sinkron"
                color="btn-primary"
                :show.sync="modalSyncAkun"
              >
                <CRow>
                  <CCol md="12">
                    <div class="form-group">
                      <template
                        v-if="
                          user_data &&
                          user_data.perusahaan &&
                          user_data.perusahaan.bentuk_perusahaan_id !== null &&
                          user_data.perusahaan.bentuk_perusahaan_id === 3
                        "
                      >
                        <b>NIB<span class="text-danger">* </span></b>
                        <input
                          type="text"
                          class="form-control"
                          v-model="nib_perusahaan"
                          readonly
                        />
                      </template>
                      <template
                        v-if="
                          user_data &&
                          user_data.perusahaan &&
                          user_data.perusahaan.bentuk_perusahaan_id !== null &&
                          user_data.perusahaan.bentuk_perusahaan_id !== 3
                        "
                      >
                        <b>NPWP<span class="text-danger">*</span></b>
                        <input
                          type="text"
                          class="form-control"
                          v-model="npwp_perusahaan"
                          readonly
                        />
                      </template>
                      <template>
                        <b>NIB<span class="text-danger">*</span></b>
                        <input
                          type="text"
                          class="form-control"
                          v-model="nib_perusahaan"
                          readonly
                        />
                      </template>
                    </div>
                  </CCol>

                  <CCol md="12">
                    <div class="form-group">
                      <b>Password <span class="text-danger">*</span></b>
                      <input
                        type="password"
                        class="form-control"
                        v-model="password_sync"
                      />
                    </div>
                  </CCol>
                </CRow>
                <template #footer>
                  <CButton @click="modalSyncAkun = false" color="secondary"
                    >Tutup</CButton
                  >
                  <CButton v-on:click="syncAkun()" color="primary"
                    >Simpan</CButton
                  >
                </template>
              </CModal>
            </div>
          </template>
					<!-- <CCard class="top-radius-15 form-member" id="formAddMember" style="display: none;">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h4 class="my-0 text-center"><b>Tambah Member</b></h4>
						</CCardHeader>
						<CCardBody>
							<CRow class="mt-3">
								<CCol md="6">
									<div class="form-group">
										<b>Nama<span class="text-danger">*</span></b>
										<input type="text" class="form-control" id="memberbaru-nama" />
									</div>
								</CCol>
								<CCol md="6">
									<div class="form-group">
										<b>Email Pendaftar <span class="text-danger">*</span></b>
										<input type="email" class="form-control" id="memberbaru-email" />
									</div>
								</CCol>
								<CCol md="6">
									<div class="form-group">
										<b>Password <span class="text-danger">*</span></b>
										<input type="password" class="form-control" id="memberbaru-password" />
									</div>
								</CCol>
								<CCol md="6">
									<div class="form-group">
										<b>Konfirmasi Password <span class="text-danger">*</span></b>
										<input type="password" class="form-control" id="memberbaru-confirmpassword" />
									</div>
								</CCol>
								<CCol md="12">
									<div class="btn btn-success btn-hover-outline-success btn-block" v-on:click="registerMemberProcess"><i class="fa fa-save"></i> Simpan</div>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard>
					<CCard class="top-radius-15 form-member" id="formEditMember" style="display: none;">
						<CCardHeader class="top-radius-15 bg-success text-white">
							<h4 class="my-0 text-center"><b>Edit Member</b></h4>
						</CCardHeader>
						<CCardBody>
							<CRow class="mt-3">
								<CCol md="6">
									<div class="form-group">
										<label for=""><b>Nama</b></label>
										<input type="text" class="form-control" v-model="member_edit_nama"/>
									</div>
								</CCol>
								<CCol md="6">
									<div class="form-group">
										<label for=""><b>Email</b></label>
										<input type="email" class="form-control" v-model="member_edit_email"/>
									</div>
								</CCol>
								<CCol md="6">
									<div class="form-group">
										<label for=""><b>Password</b></label>
										<input type="Password" class="form-control" placeholder="******" v-model="member_edit_password"/>
									</div>
								</CCol>
								<CCol md="6">
									<div class="form-group">
										<label for=""><b>Confirm Password</b></label>
										<input type="Password" class="form-control" placeholder="******" v-model="member_edit_password_confirm" />
									</div>
								</CCol>
								<CCol md="12">
									<div class="btn btn-success btn-hover-outline-success btn-block"><i class="fa fa-save"></i> Simpan</div>
								</CCol>
							</CRow>
						</CCardBody>
					</CCard> -->
				</CCol>
			</CRow>
		</div>
	</div>
</template>
<script>
	import "core-js/stable";
    import Vue from "vue";
	const empty_array = [];
	export default {
		name: "ProfilePemohon",
		data() {
			return {
				token: this.access_token,
				
				session_nama: this.session_nama,
				data_perusahaan: empty_array,
				data_profile_perusahaan: empty_array,
				data_infestasi: empty_array,
				data_pemegang_saham: empty_array,
				data_penanggung_jawab: empty_array,
				data_legalitas: empty_array,
				data_rptka_jabatan: empty_array,
				data_rptka_negara: empty_array,
				data_rptka_lokasi: empty_array,
				data_data_dni: empty_array,
				data_data_checklist: empty_array,
				data_data_proyek: empty_array,
				data_data_member: empty_array,
				collapse: false,
				cardCollapse: true,
				innerCollapse: false,
				accordion: 0,
				session_data: JSON.parse(this.session),
				// edit member
				member_edit_nama: '',
				member_edit_email: '',
				member_edit_password: '',
				member_edit_password_confirm: '',
				is_edited: '',
				nib_sync: '',
				tgl_perbarui: '',
				modalTambahMember: false,
				modalEditMember: false,
      modalSyncAkun: false,
				memberbaru_nama: '',
				memberbaru_email: '',
				memberbaru_password: '',
				memberbaru_confirmpassword: '',
				memberbaru_id: '',
				// Edit Instansi
				data_perusahaan_register: [],
				master_provinsi: [],
				kecamatan_selected: [],
				kota_selected: [],
				provinsi_selected: [],
				jenis_usaha: [],
				master_jenis_perusahaan: [],
				perusahaan_kbli: [],
				master_kbli_perusahaan: [],
      user_data: "",
      npwp_perusahaan: "",
      nib_perusahaan: "",
      password_sync: "",
			};
		},
		mounted() {
			$(".select2").select2({
				placeholder: "-- Pilih --",
			});
		},
		methods: {
			deleteDataMember(id) {
                // alert(id);
				let self = this;
                Swal.fire({
                    title: "Apakah anda yakin?",
                    text: "Anda tidak bisa mengembalikan data yang dihapus!",
                    showDenyButton: true,
                    showCancelButton: false,
                    confirmButtonText: `Hapus`,
                    denyButtonText: `Batal`,
                }).then((result) => {
                    /* Read more about isConfirmed, isDenied below */
                    if (result.isConfirmed) {
                        Swal.fire({
                            title: '<i class="fa fa-refresh fa-spin"></i>',
                            text: "Mohon Tunggu Sebentar.",
                            showConfirmButton: false,
                        });
                        axios
                            .delete(this.apiLink + "api/crud/user", {
                                headers: {
                                    Authorization: "Bearer " + this.access_token,
                                },
                                data: {
                                    id: id,
                                },
                            })
                            .then((response) => {
                                var res_data = response.data;
                                
                                if (res_data.meta.code != "200") {
                                    Swal.fire({
                                        icon: "error",
                                        title: "Oops...",
                                        text: res_data.data.message,
                                    });
                                } else {
                                    let timerInterval;
                                    Swal.fire({
                                        icon: "success",
                                        title: "Berhasil",
                                        text: "Data member berhasil dihapus.",
                                        timer: 2000,
                                        timerProgressBar: true,
                                        showCancelButton: false,
                                        showConfirmButton: false,
                                        didOpen: () => {
                                            timerInterval = setInterval(() => {
                                                const content = Swal.getContent();
                                                if (content) {
                                                    const b = content.querySelector("b");
                                                    if (b) {
                                                        b.textContent = Swal.getTimerLeft();
                                                    }
                                                }
                                            }, 100);
                                        },
                                        willClose: () => {
                                            clearInterval(timerInterval);
                                        },
                                    }).then((result) => {
                                        /* Read more about handling dismissals below */
                                        if (result.dismiss === Swal.DismissReason.timer) {
											self.refreshMember();
                                        }
                                    });
                                }
                            });
                    } else if (result.isDenied) {
                        Swal.close();
                    }
                });
            },
			syncPerusahaan(){
				let self = this;
				// this.newSession();
				// return false;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon Tunggu Sebentar.",
					showConfirmButton: false,
				});
				axios.put(
                    this.apiLink + "api/crud/user/perusahaan",
                    {
                        perusahaan_id: this.session_data.perusahaan_id,
                        nib: this.nib_sync
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        self.newSession();
                    }
                })
                .catch((error) => {
					
					if (error) {
						Swal.fire({
							icon: "error",
							title: "Gagal",
							text: error.response.data.data.message,
						});
					}
				});
				// alert(this.nib_sync);
			},
			newSession(){
				axios
				.get(this.apiLink + "api/user", {
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					
					// Tab data Akun
					// var datauser = response.data.data;
					Vue.prototype.session = JSON.stringify(response.data.data);
					localStorage.session = JSON.stringify(response.data.data);

					let timerInterval;
                    Swal.fire({
                        icon: "success",
                        title: "Berhasil",
                        text: "Data perusahaan anda berhasil di update",
                        timer: 2000,
                        timerProgressBar: true,
                        showCancelButton: false,
                        showConfirmButton: false,
                        didOpen: () => {
                            timerInterval = setInterval(() => {
                                const content = Swal.getContent();
                                if (content) {
                                    const b = content.querySelector("b");
                                    if (b) {
                                        b.textContent = Swal.getTimerLeft();
                                    }
                                }
                            }, 100);
                        },
                        willClose: () => {
                            clearInterval(timerInterval);
                        },
                    }).then((result) => {
                        /* Read more about handling dismissals below */
                        if (result.dismiss === Swal.DismissReason.timer) {
                        	window.location.reload();
                        }
                    });
				});

				// alert('wd');
			},
			showAddMember() {
				this.modalTambahMember = true;
			},
    showModalSync() {
      this.modalSyncAkun = true;
    },
			showEditMember(id_member) {
				var member = this.data_data_member.find(members_data => {
                    if (members_data.id === id_member) {
                        return members_data;
                    }
                });
				this.memberbaru_nama = member.name;
				this.memberbaru_email = member.email;
				this.memberbaru_id = member.id;
				
				this.modalEditMember = true;
			},
			updateDataUser() {
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon Tunggu Sebentar.",
					showConfirmButton: false,
				});
				var FormData = require("form-data");
				var data = new FormData();
				data.append("name", $("#nama").val());
				data.append("email", $("#email").val());
				data.append("password", $("#password").val());
				data.append("password_confirmation", $("#confirmpassword").val());
				var config = {
					method: "post",
					url: this.apiLink + "api/user",
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
					data: data,
				};
				axios(config)
					.then((response) => {
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Data Anda Berhasil Disimpan",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								
							}
						});
					})
					.catch((error) => {
						
						if (error) {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Terjadi Kesalahan, Coba Lagi Nanti",
							});
						}
					});
			},
			refreshMember(){
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon Tunggu Sebentar",
					showConfirmButton: false,
				});
				axios
				.get(this.apiLink + "api/crud/user", {
					params: {
						nib: this.session_data.nib,
						perusahaan_id: this.session_data.perusahaan_id,
					},
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					var res_user = response.data;
					var res_user_data = res_user.data[0];
					
					if (res_user.meta.code != "200") {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res_user.data.message,
						});
					} else {
						swal.close();
						this.data_data_member = res_user_data.data;
					}
				});
			},
			registerMemberProcess() {
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon Tunggu Sebentar.",
					showConfirmButton: false,
				});
				let self = this;
				var FormData = require('form-data');
				var data = new FormData();
				data.append('name', $("#memberbaru-nama").val());
				data.append('email', $("#memberbaru-email").val());
				data.append('password', $("#memberbaru-password").val());
				data.append('password_confirmation', $("#memberbaru-confirmpassword").val());
				data.append('nib', this.session_data.nib);
				data.append('tipe_pemohon_id', this.session_data.tipe_pemohon_id);
				data.append('perusahaan_id', this.session_data.perusahaan_id);
				var config = {
				  method: 'post',
				  url: this.apiLink + 'api/crud/member',
				  headers: { 
					Authorization: "Bearer " + this.access_token,
				  },
				  data : data
				};
				axios(config)
				.then((response) => {
					let timerInterval;
					Swal.fire({
						icon: "success",
						title: "Berhasil",
						text: "Data member baru berhasil disimpan",
						timer: 2000,
						timerProgressBar: true,
						showCancelButton: false,
						showConfirmButton: false,
						didOpen: () => {
							timerInterval = setInterval(() => {
								const content = Swal.getContent();
								if (content) {
									const b = content.querySelector("b");
									if (b) {
										b.textContent = Swal.getTimerLeft();
									}
								}
							}, 100);
						},
						willClose: () => {
							clearInterval(timerInterval);
						},
					}).then((result) => {
						/* Read more about handling dismissals below */
						if (result.dismiss === Swal.DismissReason.timer) {
							
							self.modalTambahMember =  false;
							self.modalEditMember =  false;
							self.refreshMember();
						}
					});
				})
				.catch((error) => {
					
					if (error) {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: "Terjadi Kesalahan, Coba Lagi Nanti",
						});
					}
				});
			},
			editMemberProcess: function () {
                Swal.fire({
                    title: '<i class="fa fa-refresh fa-spin"></i>',
                    text: "Mohon Tunggu Sebentar.",
                    showConfirmButton: false,
                    allowOutsideClick: false,
                });
				let self = this;
                axios.put(
                    this.apiLink + "api/crud/user",
                    {
						
                        id: this.memberbaru_id,
                        name:this.memberbaru_nama,
                        email:this.memberbaru_email,
                        password:this.memberbaru_password,
                        password_confirmation:this.memberbaru_confirmpassword,
						roles_id: 2,
                    },
                    {
                        headers: {
                            Authorization: "Bearer " + this.access_token,
                        },
                    }
                )
                .then(function (response) {
                    var res_data = response.data;
                    if (res_data.meta.code != "200") {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res_user.data.message
                        });
                    }
                    else{
                        let timerInterval;
                        Swal.fire({
                            icon: "success",
                            title: "Berhasil",
                            text: "Data member anda berhasil disimpan",
                            timer: 2000,
                            timerProgressBar: true,
                            showCancelButton: false,
                            showConfirmButton: false,
                            didOpen: () => {
                                timerInterval = setInterval(() => {
                                    const content = Swal.getContent();
                                    if (content) {
                                        const b = content.querySelector("b");
                                        if (b) {
                                            b.textContent = Swal.getTimerLeft();
                                        }
                                    }
                                }, 100);
                            },
                            willClose: () => {
                                clearInterval(timerInterval);
                            },
                        }).then((result) => {
                            /* Read more about handling dismissals below */
                            if (result.dismiss === Swal.DismissReason.timer) {
                                self.modalTambahMember =  false;
								self.modalEditMember =  false;
								self.refreshMember();
                            }
                        });
                    }
                });
            },
			updatePerusahaanRegister() {
				let self = this;
				Swal.fire({
					title: '<i class="fa fa-refresh fa-spin"></i>',
					text: "Mohon Tunggu Sebentar.",
					showConfirmButton: false,
				});
				var FormData = require("form-data");
				var data = new FormData();
				data.append('id', self.data_perusahaan_register.id);
				data.append('p_nama', self.data_perusahaan_register.p_nama);
				data.append('p_npwp', self.data_perusahaan_register.p_npwp);
				data.append('p_penanggung_jawab', self.data_perusahaan_register.p_penanggung_jawab);
				data.append('p_email', self.data_perusahaan_register.p_email);
				data.append('p_telp', self.data_perusahaan_register.p_telp);
				data.append('p_alamat', self.data_perusahaan_register.p_alamat);
				data.append('jenis_perusahaan_json', JSON.stringify(self.jenis_usaha) );
				data.append('perusahaan_kbli_json', JSON.stringify(self.perusahaan_kbli));
				data.append('p_hp', self.data_perusahaan_register.p_hp);
				data.append('p_fax', self.data_perusahaan_register.p_fax);
				data.append('provinsi_id', self.provinsi_selected.id);
				data.append('kota_id', self.kota_selected.id);
				data.append('kecamatan_id', self.kecamatan_selected.id);
				data.append('p_kode_pos', self.data_perusahaan_register.p_kode_pos);
				var config = {
					method: "post",
					url: this.apiLink + "api/crud/perusahaan/update_perusahaan",
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
					data: data,
				};
				axios(config)
					.then((response) => {
						let timerInterval;
						Swal.fire({
							icon: "success",
							title: "Berhasil",
							text: "Data Anda Berhasil Disimpan",
							timer: 2000,
							timerProgressBar: true,
							showCancelButton: false,
							showConfirmButton: false,
							didOpen: () => {
								timerInterval = setInterval(() => {
									const content = Swal.getContent();
									if (content) {
										const b = content.querySelector("b");
										if (b) {
											b.textContent = Swal.getTimerLeft();
										}
									}
								}, 100);
							},
							willClose: () => {
								clearInterval(timerInterval);
							},
						}).then((result) => {
							/* Read more about handling dismissals below */
							if (result.dismiss === Swal.DismissReason.timer) {
								
							}
						});
					})
					.catch((error) => {
						
						if (error) {
							Swal.fire({
								icon: "error",
								title: "Oops...",
								text: "Terjadi Kesalahan, Coba Lagi Nanti",
							});
						}
					});
			},
    syncAkun() {
      let self = this;
      Swal.fire({
        title: '<i class="fa fa-refresh fa-spin"></i>',
        text: "Mohon Tunggu Sebentar.",
        showConfirmButton: false,
      });
      axios
        .post(
          this.apiLink + "api/crud/user/sync-account",
          {
            password: self.password_sync,
          },
          {
            headers: {
              Authorization: "Bearer " + this.access_token,
            },
          }
        )
        .then(function (response) {
          var res_data = response.data;
          console.log("sukses : ", res_data);
          Swal.fire({
            icon: "success",
            title: "Sukses.",
            text: "Sinkronisasi akun berhasil!",
          });
          self.modalSyncAkun = false;
        })
        .catch((error) => {
          console.log("error : ", error);
          Swal.fire({
            icon: "error",
            title: "Gagal",
            text: error.response.data.meta.message,
          });
          //   self.modalSyncAkun = false;
        });
    },
		},
		created(){
			
			Swal.fire({
				title: '<i class="fa fa-refresh fa-spin"></i>',
				text: "Mohon Tunggu Sebentar.",
				showConfirmButton: false,
			});
			// Get Master Daerah (Provinsi)
			axios.get(this.apiLink + "api/master/chain_daerah", {
				
			})
			.then((response) => {
				var res_master_daerah = response.data;
				var res_master_daerah_data = res_master_daerah.data;
				if (res_master_daerah.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_master_daerah_data.data.message
					});
				}
				else{
					this.master_provinsi = res_master_daerah_data.master;
					
				}
			});
			// Get Master Jenis Perusahaan
			axios.get(this.apiLink + "api/master/jenis_perusahaan", {
			})
			.then((response) => {
				var res_master_jenis_perusahaan = response.data;
				var res_master_jenis_perusahaan_data = res_master_jenis_perusahaan.data;
				if (res_master_jenis_perusahaan.meta.code != "200") {
					Swal.fire({
						icon: 'error',
						title: 'Oops...',
						text: res_master_jenis_perusahaan.data.message
					});
				}
				else{
					this.master_jenis_perusahaan = res_master_jenis_perusahaan_data.master;
				}
			});
			// Get Member
			axios
				.get(this.apiLink + "api/crud/user", {
					params: {
						nib: this.session_data.nib,
						perusahaan_id: this.session_data.perusahaan_id,
					},
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					var res_user = response.data;
					var res_user_data = res_user.data[0];
					
					if (res_user.meta.code != "200") {
						Swal.fire({
							icon: "error",
							title: "Oops...",
							text: res_user.data.message,
						});
					} else {
						this.data_data_member = res_user_data.data;
					}
				});
			axios
				.get(this.apiLink + "api/user", {
					headers: {
						Authorization: "Bearer " + this.access_token,
					},
				})
				.then((response) => {
					swal.close();
					// Tab data Akun
					var datauser = response.data.data;
					$("#nama").val(datauser.name);
					// $("#nip").val(datauser.nip);
					$("#email").val(datauser.email);
					$("#password").val(datauser.password);
					$("#confirmpassword").val(datauser.password_confirmation);
					// $("#nibmember").val(datauser.nib);
					$("#phone").val(datauser.phone);
					// $("#npwpmember").val(datauser.npwp);
					// Tab Data Perusahaan
					this.tgl_perbarui = response.data.data.tgl_perbarui;
					this.data_perusahaan = response.data.data.perusahaan_json;
					this.data_perusahaan_register = response.data.data.perusahaan_register;
        this.user_data = response.data.data.perusahaan;
        this.nib_perusahaan = response.data.data.perusahaan.nib;
        this.npwp_perusahaan = response.data.data.perusahaan.p_npwp;
					if(this.data_perusahaan_register?.tipe_pemohon_id == 2){
						this.kecamatan_selected = response.data.data.perusahaan_register.kecamatan;
						this.kota_selected = response.data.data.perusahaan_register.kecamatan.kota;
						this.provinsi_selected = response.data.data.perusahaan_register.kecamatan.kota.provinsi;
						this.jenis_usaha = JSON.parse(response.data.data.perusahaan_register.jenis_perusahaan_json);
					}
					
					if (response.data.data.perusahaan_json) {
						this.is_edited = false;
						var datanibperusahaan = response.data.data.perusahaan_json.responinqueryNIB.dataNIB;
						
						var clear_data_perusahaan = {};
						$.each(datanibperusahaan, function (index, val) {
							if ($.isArray(val) || val instanceof jQuery) {
							} else {
								clear_data_perusahaan[index] = val;
							}
						});
						this.data_profile_perusahaan = clear_data_perusahaan;
						this.data_pemegang_saham = datanibperusahaan.pemegang_saham;
						this.data_penanggung_jawab = datanibperusahaan.penanggung_jwb;
						this.data_legalitas = datanibperusahaan.legalitas;
						this.data_rptka_negara = datanibperusahaan.data_rptka.rptka_negara;
						this.data_rptka_lokasi = datanibperusahaan.data_rptka.rptka_lokasi;
						this.data_data_dni = datanibperusahaan.data_dni;
						this.data_data_checklist = datanibperusahaan.data_checklist;
						this.data_data_proyek = datanibperusahaan.data_proyek;
					}
					else{
						this.is_edited = true;
					}
					// // Data Member Sementara
					// var member = [{
					//                         'member_nama': datauser.name,
					//                         'member_email': datauser.email,
					//                     }];
					// this.data_data_member = member;
				});
		}
	};
</script>